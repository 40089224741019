import Checkout from "./Checkout.js";
// import { model } from "./config.js";
// import Localizer from "./Localizer.js";
// let localizer = new Localizer();

/**
* creates order-button element with input for quantity and button for ordering
* adds item to cart when clicked
* attributes:
* - data-namecode: namecode for item to order
* - data-sizecode: sizecode for item to order
*/
class OrderButton extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.innerHTML = `<input type="number" id="quantity" value="1" /><button id="buy" disabled>${this.dataset.text || ""}</button>`;
    let b = this.querySelector('button#buy');
    model.onload(function() { 
      b.disabled = false; 
      localizer.localize(this);
    }.bind(this));
    b.addEventListener("click", this.buy.bind(this));
  }
  /**
  * buy item, click callback
  */
  buy() {
    if (!this.dataset.namecode) throw "OrderButton: no namecode";
    if (!this.dataset.sizecode) throw "OrderButton: no sizecode";
    let item = model.findItem(this.dataset.namecode, this.dataset.sizecode);
    if (item) {
      item.quantity = this.querySelector("input#quantity").value;
    }
    let c = new Checkout();
    c.add_item(item);
  }
}
customElements.define("order-button", OrderButton);
